import styled from '@emotion/styled';
import React from 'react';
import { Typography } from './Typography.tsx';
import LogoIcon  from '../logo-icon.svg'
import { selectUser } from '../../../redux/Userslice.js';
import { useSelector } from 'react-redux';
import { useThemeOutlet } from '../../../context/ThemeContext.js';


interface SidebarHeaderProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  rtl: boolean;
}
const StyledSidebarHeader = styled.div`
  height: 64px;
  min-height: 64px;
  display: flex;
  align-items: center;
  padding: 0 20px;

  > div {
    width: 100%;
    overflow: hidden;
  }
`;

const StyledLogo = styled.div<{ rtl?: boolean }>`
  width: 35px;
  min-width: 35px;
  height: 35px;
  min-height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  color: white;
  font-size: 24px;
  font-weight: 700;
  background-color: #7839cd;
  background: linear-gradient(45deg, #7839cd 0%, rgb(210 90 255) 100%);
  ${({ rtl }) =>
    rtl
      ? `
      margin-left: 10px;
      margin-right: 4px;
      `
      : `
      margin-right: 10px;
      margin-left: 4px;
      `}
`; 



export const SidebarHeader: React.FC<SidebarHeaderProps> = ({ children, rtl, ...rest }) => {
  const userData = useSelector(selectUser);
  const { themeOutlet, toggleThemeOutlet } = useThemeOutlet();
  
  return (

    <StyledSidebarHeader {...rest}>
      <div className='d-flex  justify-content-center fixed-user-logo'> 
        
        {/* <StyledLogo rtl={rtl}><Logoicon/></StyledLogo> */}
        {userData?.userDetails?.orgImage ? (
          <img src={userData.userDetails.orgImage} alt="Logo" width='50%' className={`${themeOutlet} hd-imag-org`}/>
        ) : (
          <>
          <img src={LogoIcon} alt="Logo" width='70%'   />
          {/* <Typography variant="subtitle1" style={{marginLeft:'4px',fontSize:'30px'}} fontWeight={800} color="#7839CD">
            LAMS
         </Typography>  */}
         </>
        )}
        {/* <img src={LogoIcon} alt="Logo" width={36}  />
         <Typography variant="subtitle1" style={{marginLeft:'4px',fontSize:'30px'}} fontWeight={800} color="#7839CD">
           LAMS
        </Typography>  */}
      </div>
    </StyledSidebarHeader> 
  );
};
